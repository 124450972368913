/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-content.container {
  --background: linear-gradient(180deg, #00bc70 0%, #3a8dde 100%);
}

.form-property {
  margin-bottom: 10px;
}

.form-label {
  color: white;
  font-size: 1.2em;
}

.text-input {
  background-color: white !important;
  color: black;
  border-radius: 10px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.password-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.primary-look-button {
  border-radius: 60px !important;
  background-color: --ion-color-primary-tint;
  border: solid 1px;
  margin-right: 10px;
  border-radius: 16px;
}

.danger-look-button {
  border-radius: 60px !important;
  background-color: crimson;
  border: solid 1px;
  margin-right: 10px;
  border-radius: 16px;
}
